.cky-btn {
  border-radius: 8px !important;
  background-color: #8C8C8C !important;
  color: white !important;
}

.cky-btn-accept {
  background-color: #4D4D4D !important;
}

input[type="checkbox"][aria-label~="Enable"] {
  background-color: #8C8C8C !important;
}

input[type="checkbox"][aria-label~="Disable"] {
  background-color: #333333 !important;
}

.cky-preference-center {
  background-color: #4D4D4D !important;
  ;
}

.cky-footer-shadow {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #4D4D4D 100%) !important;
}

.cky-accordion-btn {
  color: white !important;
}

.cky-accordion-header-des {
  color: white !important;
}

.cky-preference-content-wrapper {
  color: white !important;
}

.cky-preference-title {
  color: white !important;
}

.cky-audit-table {
  background-color: #8C8C8C !important;
}

.cky-show-desc-btn {
  color: #8C8C8C !important;
}

.cky-always-active {
  color: #8C8C8C !important;
}

div[data-cky-tag="detail-powered-by"] {
  background-color: #8C8C8C !important;
}

.cky-consent-bar {
  background-color: #4D4D4D !important;
}

.cky-title {
  color: white !important;
}

.cky-notice-des {
  color: white !important;
}

.App {
  text-align: left;
  position: flex;
  background-color: 'black';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.MuiListItem-button {
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.center-items {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap
}

.support-form-container {
  display: flex;
  width: 40%;
  min-width: min(90%, 500px);
}

.MuiPaper-rounded {
  border-radius: 8px;
}

.ad {
  background-color: brown;
}

.grid-col-span-2 {
  grid-column-end: span 2;
}

.grid-col-span-3 {
  grid-column-end: span 3;
}

@font-face {
  font-family: 'Calibre';
  src: url('/fonts/CalibreRegular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibre';
  src: url('/fonts/CalibreMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibre';
  src: url('/fonts/CalibreSemibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}