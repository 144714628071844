.node > label {
  cursor: pointer;
  margin-left: 2px;
}

.tag-list {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
}

.tag-item {
  padding: 4px 4px 4px 4px;
  width: 100%;

  .search {
    border: none;
    outline: none;
    height: 25px;
    width: 100%;
    background: rgba(249, 249, 249, 1);
    border-radius: 8px;
  }
}

.tag-item:last-child {
  margin-right: 4px;
}
