.swiper{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    display: flex;
    overflow: hidden;
    width: 100%;
}

.swiper-arrow{
    position: absolute;
    width: 50px;
    z-index: 1;
}