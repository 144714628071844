.dividends-container{
    display: grid;
    width: 100%;
    height: 60vh;
    margin: 20px 0 20px 0;
    grid-template-columns: 1fr 2fr;
}

@media (max-width: 1000px) {
    .dividends-container{
        grid-template-columns: 1fr;
        height: 140vh;
    }
}