.title {
    margin: auto;
}

.header {
    position: absolute;
    justify-content: space-between;
    z-index: 3;
}

.navigationBar {
    position: absolute;
    right: 40px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    display: flex;
}