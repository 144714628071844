.root{
    display: block;
    flex-direction: column;
    min-height: 100vh;
    box-sizing: border-box;
}

.container{
    margin-right: 0;
    box-sizing: border-box;
    display: block;
    /* padding-top: 48px; */
    height: 100%;
}

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    margin-right: 0;
    float: right;
    padding: 0;
    transition: width 0.6s;
}