.goalItem{
    margin: 10px 10px 0px 10px;
    height: auto;
    float: left;
    margin-right: 10px;
    align-self: flex-end;
}

.toolbar{
    padding: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.filters-toolbar{
    min-width: 0;
    width: 100%;
    min-width: 0px;
    overflow: visible;
}

.accordion-details{
    text-align: left;
    vertical-align: middle;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.accordion-details-elem{
    min-width: 400px;
}

.filters-toolbar-header{
    min-height: 53px;
}