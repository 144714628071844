.node {
  list-style: none;
  white-space: nowrap;
  padding: 4px;

  &.leaf {
    &.collapsed {
      display: none;
    }
  }

  &.disabled > * {
    color: gray;
    cursor: not-allowed;
  }

  &.match-in-children {
    &.hide {
      .node-label {
        opacity: 0.5;
      }
    }
  }

  &.focused {
    background-color: #f4f4f4;
  }
}

.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer;

  &::after {
    content: ' ';
  }

  &.collapsed::after {
    content: '∨';
    font-style: normal;
  }

  &.expanded::after {
    content: '-';
  }
}

.searchModeOn .toggle {
  display: none;
}

.checkbox-item,
.radio-item {
  vertical-align: middle;
  margin: 0 4px 0 0;

  &.simple-select {
    display: none;
  }
}
