.post {
    padding: 20px;
}

.post h1 {
    font-size: 1.5em;
    color: #7a7474;
}

.author {
    font-size: 0.9em;
    margin-bottom: 15px;
}

/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
    .articles {
        width: 95%;
        padding-left: 5%;
    }
}

.post-wrapper {
    display: flex;
}

.post-image {
    flex: 1;
    margin: 20px;
}

.post-text {
    flex: 2;
}