/* component styles */
.hide:not(.match-in-children) {
  display: none;
}

.react-dropdown-tree-select {
  .dropdown {
    position: relative;
    display: table;
    width: 100%;

    .dropdown-trigger {
      padding: 4px;
      line-height: 20px;
      max-height: 200px;
      display: inline-block;
      overflow: auto;
      width: 100%;
    }

    .dropdown-content {
      padding: 4px;
      padding-left: 15px;
      z-index: 1;
      background: white;
      max-height: 100px;
      overflow-y: auto;
      display: flex;
      min-width: 120px;
      height: auto;
      border-radius: 8px;
      width: 100%;

      .search {
        width: 100%;
        border: none;
        border-bottom: solid 1px #ccc;
        outline: none;
      }

      ul {
        margin: 0;
        padding: 0;
      }
    }
  }
}
