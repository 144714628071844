.drawer{
    height: 100%;
    overflow-x: hidden;
    transition: width 0.6s;
    position: fixed;
    overflow-y: auto;
    display: flex;
    flex-flow: column;
}

.temporary-drawer{
    position: absolute;
    z-index: 10000;
    height: 100%;
}

.drawer-elems{
    flex: 0 1 auto;
}

.drawer-ads{
    flex: 1 1 auto;
}