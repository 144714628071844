.list-icon {
    min-width: auto;
    padding-right: 10px;
}

.list-item {
    overflow-y: hidden;
    overflow-x: hidden;
}

.list-item a {
    color: #707070;
    font-size: 13px;
    white-space: nowrap;
}

.list-item.primary {
    max-height: 40px;
}

.list-item.nested {
    max-height: 30px;
    display: flex;
    font-weight: 100;
    color: #707070
}

.item-text {
    font-size: 15px;
    white-space: nowrap;
}

.nested-button {
    position: sticky;
    margin-right: -30px;
}

.hide {
    display: none;
}

.nested-menu-icon {
    height: 10px;
    margin: 0;
    padding: 0;
    margin-left: 30px;
    margin-right: 5px;
}