.growing-page{
    transition: transform .5s ease-in-out;
    position: relative;
    margin-left: -70px;
    margin-top: 30px;
    height: max-content;
}

.growing-page:first-child{
    margin-left: 40px;
}

.growing-page:hover~.growing-page{
    transform: translateX(70px)
}

.growing-page:hover {
    transform: rotate(1deg) scale(1.1);
}